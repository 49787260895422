<template>
  <div id="misc">
    <div class="page-title text-center px-4">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <v-icon
          color="warning"
        >
          {{ icons.mdiAlert }}
        </v-icon>
        <span class="me-2">&nbsp;{{ $t('Page Not Found') }}</span>
      </h2>

      <v-btn
        color="primary"
        to="/"
        class="mb-4 mt-6"
      >
        {{ $t('Close') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
